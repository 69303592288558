export const staffHeatMapdata = {
  series: [
    {
      name: 'Jan',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Feb',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Mar',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Apr',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'May',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Jun',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Jul',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Aug',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    },
    {
      name: 'Sep',
      data: generateData(20, {
        min: -30,
        max: 55
      })
    }
  ],
  chartOptions: {
    chart: {
      height: 350,
      type: 'heatmap'
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: -30,
              to: 5,
              name: 'low',
              color: '#00A100'
            },
            {
              from: 6,
              to: 20,
              name: 'medium',
              color: '#128FD9'
            },
            {
              from: 21,
              to: 45,
              name: 'high',
              color: '#FFB200'
            },
            {
              from: 46,
              to: 55,
              name: 'extreme',
              color: '#FF0000'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1
    },
    title: {
      text: 'HeatMap Chart with Color Range'
    }
  }
};

export const overViewData = [
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  },
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  },
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  },
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  },
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  },
  {
    title: 'Metrics 1',
    infoValue: '3277.825',
    rate: '66.96'
  }
];

function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push(y);
    i++;
  }
  return series;
}

export const trafficPowerHour = {
  data: {
    Thursday: [
      {
        hour: '09:00 - 10:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '10:00 - 11:00',
        value: 5,
        formattedValue: '5%'
      },
      {
        hour: '11:00 - 12:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '12:00 - 13:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '13:00 - 14:00',
        value: 13,
        formattedValue: '13%'
      },
      {
        hour: '14:00 - 15:00',
        value: 13,
        formattedValue: '13%'
      },
      {
        hour: '15:00 - 16:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '16:00 - 17:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '17:00 - 18:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '18:00 - 19:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '19:00 - 20:00',
        value: 7,
        formattedValue: '7%'
      },
      {
        hour: '20:00 - 21:00',
        value: 3,
        formattedValue: '3%'
      },
      {
        hour: '21:00 - 22:00',
        value: 1,
        formattedValue: '1%'
      }
    ],
    Monday: [
      {
        hour: '09:00 - 10:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '10:00 - 11:00',
        value: 5,
        formattedValue: '5%'
      },
      {
        hour: '11:00 - 12:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '12:00 - 13:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '13:00 - 14:00',
        value: 12,
        formattedValue: '12%'
      },
      {
        hour: '14:00 - 15:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '15:00 - 16:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '16:00 - 17:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '17:00 - 18:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '18:00 - 19:00',
        value: 8,
        formattedValue: '8%'
      },
      {
        hour: '19:00 - 20:00',
        value: 7,
        formattedValue: '7%'
      },
      {
        hour: '20:00 - 21:00',
        value: 3,
        formattedValue: '3%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ],
    Friday: [
      {
        hour: '09:00 - 10:00',
        value: 1,
        formattedValue: '1%'
      },
      {
        hour: '10:00 - 11:00',
        value: 30,
        formattedValue: '30%'
      },
      {
        hour: '11:00 - 12:00',
        value: 36,
        formattedValue: '36%'
      },
      {
        hour: '12:00 - 13:00',
        value: 32,
        formattedValue: '32%'
      },
      {
        hour: '13:00 - 14:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '14:00 - 15:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '15:00 - 16:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '16:00 - 17:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '17:00 - 18:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '18:00 - 19:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '19:00 - 20:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '20:00 - 21:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ],
    Sunday: [
      {
        hour: '09:00 - 10:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '10:00 - 11:00',
        value: 6,
        formattedValue: '6%'
      },
      {
        hour: '11:00 - 12:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '12:00 - 13:00',
        value: 8,
        formattedValue: '8%'
      },
      {
        hour: '13:00 - 14:00',
        value: 12,
        formattedValue: '12%'
      },
      {
        hour: '14:00 - 15:00',
        value: 14,
        formattedValue: '14%'
      },
      {
        hour: '15:00 - 16:00',
        value: 14,
        formattedValue: '14%'
      },
      {
        hour: '16:00 - 17:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '17:00 - 18:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '18:00 - 19:00',
        value: 8,
        formattedValue: '8%'
      },
      {
        hour: '19:00 - 20:00',
        value: 5,
        formattedValue: '5%'
      },
      {
        hour: '20:00 - 21:00',
        value: 2,
        formattedValue: '2%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ],
    Wednesday: [
      {
        hour: '09:00 - 10:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '10:00 - 11:00',
        value: 6,
        formattedValue: '6%'
      },
      {
        hour: '11:00 - 12:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '12:00 - 13:00',
        value: 12,
        formattedValue: '12%'
      },
      {
        hour: '13:00 - 14:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '14:00 - 15:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '15:00 - 16:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '16:00 - 17:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '17:00 - 18:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '18:00 - 19:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '19:00 - 20:00',
        value: 5,
        formattedValue: '5%'
      },
      {
        hour: '20:00 - 21:00',
        value: 3,
        formattedValue: '3%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ],
    Tuesday: [
      {
        hour: '09:00 - 10:00',
        value: 1,
        formattedValue: '1%'
      },
      {
        hour: '10:00 - 11:00',
        value: 7,
        formattedValue: '7%'
      },
      {
        hour: '11:00 - 12:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '12:00 - 13:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '13:00 - 14:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '14:00 - 15:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '15:00 - 16:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '16:00 - 17:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '17:00 - 18:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '18:00 - 19:00',
        value: 12,
        formattedValue: '12%'
      },
      {
        hour: '19:00 - 20:00',
        value: 7,
        formattedValue: '7%'
      },
      {
        hour: '20:00 - 21:00',
        value: 4,
        formattedValue: '4%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ],
    Saturday: [
      {
        hour: '09:00 - 10:00',
        value: 0,
        formattedValue: '0%'
      },
      {
        hour: '10:00 - 11:00',
        value: 6,
        formattedValue: '6%'
      },
      {
        hour: '11:00 - 12:00',
        value: 5,
        formattedValue: '5%'
      },
      {
        hour: '12:00 - 13:00',
        value: 8,
        formattedValue: '8%'
      },
      {
        hour: '13:00 - 14:00',
        value: 9,
        formattedValue: '9%'
      },
      {
        hour: '14:00 - 15:00',
        value: 14,
        formattedValue: '14%'
      },
      {
        hour: '15:00 - 16:00',
        value: 13,
        formattedValue: '13%'
      },
      {
        hour: '16:00 - 17:00',
        value: 13,
        formattedValue: '13%'
      },
      {
        hour: '17:00 - 18:00',
        value: 11,
        formattedValue: '11%'
      },
      {
        hour: '18:00 - 19:00',
        value: 10,
        formattedValue: '10%'
      },
      {
        hour: '19:00 - 20:00',
        value: 7,
        formattedValue: '7%'
      },
      {
        hour: '20:00 - 21:00',
        value: 3,
        formattedValue: '3%'
      },
      {
        hour: '21:00 - 22:00',
        value: 0,
        formattedValue: '0%'
      }
    ]
  }
};

export const staffCoordinates = {
  storeCode: '33064',
  storeName: 'ビックカメラ池袋本店9G',
  tenant: 'FlowSolutions',
  floorName: 'secondFloor',
  floorLevel: '2F',
  floorPlanImageUrl: 'https://dummyimage.com/600x400/000/fff',
  staffDetails: [
    {
      locY: 0.38,
      locX: 0.22,
      locationLat: 51.5355157,
      locationLong: 0.27,
      name: 'david',
      address: 'tokyo',
      phone: '1238737373',
      skypeID: '1skypeid',
      email: 'abc@email.com',
      isConnected: true,
      macAddress: '18:fe:34:d7:7c:26',
      lastSeenTime: '2020-01-31 23:59:59',
      manufacturer: 'Murata Manufacturing'
    },
    {
      locY: 0.38,
      locX: 0.22,
      locationLat: 51.5366,
      locationLong: 0.27,
      name: 'turbo',
      address: 'Shibuya',
      phone: '1238734324233',
      skypeID: '123@skypeid',
      email: 'def@email.com',
      isConnected: true,
      macAddress: '00:18:0a:13:dd:b0',
      lastSeenTime: '2020-01-31 23:59:59',
      manufacturer: 'Edimax Technology'
    }
  ]
};
