
import {
  staffHeatMapdata,
  overViewData
} from '../util/mockData/staffHeatMapData';
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      heatMapData: staffHeatMapdata,
      overViewDataLocal: [],
      staffRankingTableData: [],
      staffRankTableColumn: [
        {
          name: 'rank',
          required: true,
          label: this.$t('Rank'),
          align: 'left',
          field: row => row.rank,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'name',
          align: 'center',
          label: this.$t('Name'),
          field: 'name',
          sortable: true
        },
        {
          name: 'location',
          label: this.$t('Location'),
          field: 'location',
          sortable: true
        },
        {
          name: 'transactionSize',
          label: this.$t('TransactionSize'),
          field: 'transactionSize',
          sortable: true
        },
        {
          name: 'hoursWorked',
          label: this.$t('HoursWorked'),
          field: 'hoursWorked',
          sortable: true
        },
        {
          name: 'KPI1',
          label: 'KPI 1',
          field: 'KPI1',
          sortable: true
        },
        {
          name: 'KPI2',
          label: 'KPI 2',
          field: 'KPI2',
          sortable: true,
          sort: (a, b) => parseInt(a, 10) - parseInt(b, 10)
        }
      ],
      series: [
        {
          name: 'Jan',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Feb',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Mar',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Apr',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'May',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Jun',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Jul',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Aug',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        },
        {
          name: 'Sep',
          data: this.generateData(7, {
            min: -30,
            max: 55
          })
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'heatmap'
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: -30,
                  to: 20,
                  name: this.$t('low'),
                  color: '#ff1818'
                },
                {
                  from: 21,
                  to: 55,
                  name: this.$t('high'),
                  color: '#00A100'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        title: {
          text: this.$t('SCHEDULER')
        }
      },
      lineSeries: [
        {
          name: 'High - 2013',
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: 'Low - 2013',
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
      lineChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: this.$t('weather_and_sales_comparison'),
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          },
          min: 5,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        }
      }
    };
  },

  computed: {
    ...mapState('staffPerformance', ['overviewInfo', 'staffRanking'])
  },
  watch: {
    overviewInfo(newValue) {
      this.overViewDataLocal = newValue;
    },
    staffRanking(newValue) {
      this.staffRankingTableData = newValue;
    }
  },
  created() {
    this.getOverViewData();
    this.getStaffRanking();
    this.heatMapData = staffHeatMapdata;
  },

  methods: {
    generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push(y);
        i++;
      }
      return series;
    },
    ...mapActions('staffPerformance', ['getOverViewData', 'getStaffRanking'])
  }
};
